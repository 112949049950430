
import { defineStore } from 'pinia'
import { usePacientesStore } from './pacientes.store'
import { useMedicamentosStore } from './medicamentos.store'
import { useMotivosAutorizacionStore } from './motivos_autorizacion.store'
import { useBodegasStore } from './bodegas.store'
import { useCalculoCuotaStore } from './calculo_cuota.store'
import { useDiagnosticosStore } from './diagnosticos.store'
import { useConfigGlobal } from './config-ov.store'
import { entregasStore } from './entregas.store'
import SyncPagosService from '../services/sync-pagos'
import OrdenService from '../services/orden.service'
import SeguimientoEntregasService from '../services/seguimiento_entregas.service'
import DuracionTratamientoService from '../services/duracion_tratamiento.service'
import FrecuenciasService from '../services/frecuencias.service'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
const _OrdenService = new OrdenService()
const _FrecuenciasService = new FrecuenciasService()
const _SyncPagosService = new SyncPagosService()
const _DuracionTratamientoService = new DuracionTratamientoService()
const _SeguimientoEntregasService = new SeguimientoEntregasService()
const medicamentosStore = useMedicamentosStore()
const motivosAutorizacionStore = useMotivosAutorizacionStore()
const pacientesStore = usePacientesStore()
const bodegasStore = useBodegasStore()
const entregas = entregasStore()
const calculoCuotaStore = useCalculoCuotaStore()
const diagnosticosStore = useDiagnosticosStore()
const configGlobal = useConfigGlobal()

export const useOrdenesStore = defineStore('ordenesStore', {
  state: () => ({
    listado_frecuencias: [],
    listado_seguimientos_entrega: [],
    listado_duraciones_tratamiento: [],
    activar_select_frecuencias: false,
    historial_documento: [],
    creando_orden: false,
    header: {
      fechaCont: dayjs().format('YYYY-MM-DD'),
      cliente: '',
      bodega: ''
    },
    udf: {
      zonificacion: '',
      diagPpal: '',
      ex_cuota: '02',
      ex_pac: '',
      cod_mpio: 0,
      numero_autorizacion: null,
      fecha_autorizacion: '',
      siniestro: null,
      dispensador: 0,
      ciudad: 0
    },
    footer: {
      comentarios: ''
    },
    limpiar_orden: false,
    orden: {
      Cliente: {},
      Bodega: {},
      DocumentLines: [],
      Paciente: {},
      MotAuto: {},
      DiagPpal: {
        CdCie: '',
        DesCie: ''
      }
    },
    medicamentos_originales_find: [],
    ultima_orden: 0,
    pago_sincronizado_sap: false,
    listado_dispensadores: [],
    listado_ciudades: []
  }),
  getters: {
    _listado_frecuencias: state => state.listado_frecuencias,
    _listado_seguimientos_entrega: state => state.listado_seguimientos_entrega,
    _listado_duraciones_tratamiento: state => state.listado_duraciones_tratamiento,
    _activar_select_frecuencias: state => state.activar_select_frecuencias,
    _creando_orden: state => state.creando_orden,
    _header: state => state.header,
    _udf: state => state.udf,
    _limpiar_orden: state => state.limpiar_orden,
    _footer: state => state.footer,
    _orden: state => state.orden,
    _ultima_orden: state => state.ultima_orden,
    _medicamentos_originales_find: state => state.medicamentos_originales_find,
    _historial_documento: state => state.historial_documento,
    _pago_sincronizado_sap: state => state.pago_sincronizado_sap,
    _listado_dispensadores: state => state.listado_dispensadores,
    _listado_ciudades: state => state.listado_ciudades
  },
  actions: {
    crearOrdenVenta () {
      this.limpiar_orden = false
      this.creando_orden = true
      if (!this.header.cliente || !this._udf.numero_autorizacion || !this._udf.fecha_autorizacion || !pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf || !this.header.fechaCont || !this.header.bodega || !this.udf.diagPpal || !this._udf.dispensador || !this._udf.ciudad) {
        console.log(
          'cliente->', this.header.cliente ? this.header.cliente : 'FALTA',
          'identificación->', pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf ? pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf : 'FALTA',
          'fecha de contabilidad->', this.header.fechaCont ? this.header.fechaCont : 'FALTA',
          'bodega->', this.header.bodega ? this.header.bodega : 'FALTA',
          'diagnotico principal->', this.udf.diagPpal ? this.udf.diagPpal : 'FALTA',
          'Numero de autorizacion->', this._udf.numero_autorizacion,
          'Fecha autorizacion->', this._udf.fecha_autorizacion,
          'dispensador--->', !this._udf.dispensador,
          'ciudad-->', !this._udf.ciudad
        )
        return Swal.fire('Error', 'Debes completar todos los campos para continuar', 'error').then(() => false)
        // return false
      } else {
        const medicamentos = []
        const camposFaltantes = []
        if (!medicamentosStore._medicamentos_tabla) return Swal.fire('Error', 'Debes ingresar minimo un medicamento para continuar', 'error').then(() => false)
        // Validación las culumnas requeridas por medicamento
        for (const i of configGlobal._columnas_tabla) {
          const objeto = {}
          var columna = i.ColName
          var requerido = i.IsRequired
          medicamentosStore._medicamentos_tabla.map((t, idx) => {
            var valor = t[columna]
            objeto[columna] = valor
            objeto.LineNum = medicamentos.length
            // Validación si el medicamento es alto costo Fecha de Autorización queda obligatorio
            if (t.U_PHR_Nivel > 3 && columna === 'U_PHR_FecAutoriza') {
              requerido = true
            }
            if (columna === 'U_PHR_Siniestro') valor = this._udf.siniestro
            if (columna === 'U_PHR_NumAutoriza') valor = this._udf.numero_autorizacion
            if (columna === 'U_PHR_FecAutoriza') valor = this._udf.fecha_autorizacion
            if (requerido && !valor) {
              camposFaltantes.push(objeto)
            }
          })
        }
        if (!camposFaltantes.length) {
          for (const i of medicamentosStore._medicamentos_tabla) {
            const objeto = {}
            for (const j of configGlobal._columnas_tabla) {
              objeto[j.ColName] = i[j.ColName]
            }
            objeto.LineStatus = i.OpenCreQty ? 0 : 1
            objeto.StockMovements = i.StockMovements.length ? i.StockMovements.filter(a => !a.apartado) : []
            objeto.apartados = i.StockMovements.length ? i.StockMovements.filter(a => a.apartado) : []
            objeto.U_PHR_NumAutoriza = this._udf.numero_autorizacion
            objeto.U_PHR_FecAutoriza = this._udf.fecha_autorizacion
            objeto.U_PHR_Siniestro = this._udf.siniestro
            if (i.StockMovements.length) {
              i.StockMovements.map(a => {
                a.Quantity = Math.abs(i.Quantity) * (-1)
                return a
              })
            }
            if (objeto.U_PHR_RegMed) {
              objeto.U_PHR_RegMed = objeto.U_PHR_RegMed.RegMedico
            }
            if (objeto.U_PHR_IPSPrest) {
              objeto.U_PHR_IPSPrest = objeto.U_PHR_IPSPrest.Id
            }
            if (objeto.U_PHR_NumDirec) {
              objeto.U_PHR_NumDirec = objeto.U_PHR_NumDirec.IDDireccionamiento
            }
            medicamentos.push(objeto)
          }
          for (const i of medicamentos) {
            i.CostingCode = bodegasStore._bodega_seleccionada.U_PHR_CentroCosto
          }
          const orden = {
            CardCode: this.header.cliente,
            U_PHR_Dispensa: this._udf.dispensador,
            U_PHR_Ciudad: this._udf.ciudad,
            DocDate: this.header.fechaCont,
            TaxDate: this.header.fechaCont,
            FechaContabilizacion: this.header.fechaCont,
            FechaDocumento: this.header.fechaCont,
            Comments: this.footer.comentarios,
            U_PHR_Bodega: this.header.bodega,
            U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf,
            U_PHR_RangSalarial: pacientesStore._paciente_seleccionado.U_ACS_Nivel,
            U_PHR_MotExon: null,
            U_PHR_AutoEntrega: 'S',
            U_PHR_AutPendiente: 'N',
            U_PHR_PagaPhr: 'N',
            U_PHR_Autorizacion: 'N',
            U_PHR_DiagnosPpal: this.udf.diagPpal,
            U_PHR_NumTurno: null,
            U_PHR_NumPagoReci: null,
            U_PHR_Zonificacion: this.udf.zonificacion,
            U_PHR_CodMunicipio: this.udf.cod_mpio,
            DocumentLines: medicamentos,
            U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf
          }
          return _OrdenService.crearOrden(orden).then(({ data }) => {
            return data
          })
          // return crearOrden()
        } else {
          console.log('estos son los campos faltantes-->', camposFaltantes)
          Swal.fire('Error', 'Debes ingresar los campos faltantes para continuar', 'error').then(() => false)
        }
      }
    },
    async actualizarOrdenVenta (id) {
      this.limpiar_orden = false
      this.creando_orden = true
      if (!this.header.cliente || !this._udf.numero_autorizacion || !this._udf.fecha_autorizacion || !pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf || !this.header.fechaCont || !this.header.bodega || !this._udf.diagPpal || !this._udf.dispensador || !this._udf.ciudad) {
        console.log(
          'cliente-->', this.header.cliente ? this.header.cliente : 'FALTA',
          'identificación-->', pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf ? pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf : 'FALTA',
          'fecha de contabilidad-->', this.header.fechaCont ? this.header.fechaCont : 'FALTA',
          'bodega-->', this.header.bodega ? this.header.bodega : 'FALTA',
          'diagnotico principal->', this.udf.diagPpal ? this.udf.diagPpal : 'FALTA',
          'Numero de autorizacion->', this._udf.numero_autorizacion,
          'Fecha autorizacion->', this._udf.fecha_autorizacion,
          'dispensador--->', !this._udf.dispensador,
          'ciudad-->', !this._udf.ciudad
        )
        return Swal.fire('Error', 'Debes completar todos los campos para continuar', 'error').then(() => false)
      } else {
        const medicamentos = []
        const camposFaltantes = []
        const medicamentosTabla = medicamentosStore._medicamentos_tabla.filter(a => !a.por_entregar)
        if (!medicamentosTabla) return Swal.fire('Error', 'Debes ingresar minimo un medicamento para continuar', 'error').then(() => false)
        // Validación las culumnas requeridas por medicamento
        for (const i of configGlobal._columnas_tabla) {
          const objeto = {}
          var columna = i.ColName
          var requerido = i.IsRequired
          medicamentosStore._medicamentos_tabla.map((t, idx) => {
            var valor = t[columna]
            objeto[columna] = valor
            // Validación si el medicamento es alto costo Fecha de Autorización queda obligatorio
            if (columna === 'U_PHR_Siniestro') valor = this._udf.siniestro
            if (columna === 'U_PHR_NumAutoriza') valor = this._udf.numero_autorizacion
            if (columna === 'U_PHR_FecAutoriza') valor = this._udf.fecha_autorizacion
            if (t.U_PHR_Nivel > 3 && columna === 'U_PHR_FecAutoriza') {
              requerido = true
            }
            if (requerido && !valor) {
              camposFaltantes.push(objeto)
            }
          })
        }
        if (!camposFaltantes.length) {
          for (const i of medicamentosStore._medicamentos_tabla) {
            const objeto = {}
            for (const j of configGlobal._columnas_tabla) {
              objeto[j.ColName] = i[j.ColName]
            }
            objeto.LineNum = medicamentos.length
            objeto.StockMovements = i.StockMovements.length ? i.StockMovements.filter(a => !a.apartado) : []
            objeto.apartados = i.StockMovements.length ? i.StockMovements.filter(a => a.apartado) : []
            if (i.cant_add) {
              objeto.Quantity = i.Quantity + i.cant_add - i.OpenCreQty
            }
            objeto.U_PHR_NumAutoriza = this._udf.numero_autorizacion
            objeto.U_PHR_FecAutoriza = this._udf.fecha_autorizacion
            objeto.U_PHR_Siniestro = this._udf.siniestro
            objeto.DelivrdQty = 0
            objeto.OpenCreQty = 0
            objeto.LineStatus = i.OpenCreQty ? 0 : 1
            objeto.cant_add = i.cant_add ? i.cant_add : 0
            if (i.StockMovements.length) {
              i.StockMovements.map(a => {
                a.Quantity = Math.abs(objeto.Quantity) * (-1)
                return a
              })
            }
            if (objeto.U_PHR_RegMed) {
              objeto.U_PHR_RegMed = objeto.U_PHR_RegMed.RegMedico
            }
            if (objeto.U_PHR_IPSPrest) {
              objeto.U_PHR_IPSPrest = objeto.U_PHR_IPSPrest.Id
            }
            if (objeto.U_PHR_NumDirec) {
              objeto.U_PHR_NumDirec = objeto.U_PHR_NumDirec.IDDireccionamiento
            }
            objeto.Id = i.id_ovdt
            medicamentos.push(objeto)
          }
          for (const i of medicamentos) {
            i.CostingCode = bodegasStore._bodega_seleccionada.U_PHR_CentroCosto
          }
          const orden = {
            CardCode: this.header.cliente,
            U_PHR_Dispensa: this._udf.dispensador,
            U_PHR_Ciudad: this._udf.ciudad,
            DocDate: this.header.fechaCont,
            TaxDate: this.header.fechaCont,
            FechaContabilizacion: this.header.fechaCont,
            FechaDocumento: this.header.fechaCont,
            Comments: this.footer.comentarios,
            U_PHR_Bodega: this.header.bodega,
            U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf,
            U_PHR_RangSalarial: pacientesStore._paciente_seleccionado.U_ACS_Nivel,
            U_PHR_MotExon: null,
            U_PHR_AutoEntrega: 'S',
            U_PHR_AutPendiente: 'N',
            U_PHR_PagaPhr: 'N',
            U_PHR_Autorizacion: 'N',
            U_PHR_DiagnosPpal: this.udf.diagPpal,
            U_PHR_NumTurno: null,
            U_PHR_NumPagoReci: null,
            U_PHR_Zonificacion: this.udf.zonificacion,
            U_PHR_CodMunicipio: this.udf.cod_mpio,
            DocumentLines: medicamentos,
            U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf
          }
          return _OrdenService.actualizarOrden(orden, id).then(async ({ data }) => {
            return data
          })
        } else {
          console.log('estos son los campos faltantes-->', camposFaltantes)
          return Swal.fire('Error', 'Debes ingresar los campos faltantes para continuar', 'error').then(() => false)
        }
      }
    },
    listarFrecuencias () {
      _FrecuenciasService.listarFrecuencias().then(({ data }) => {
        this.listado_frecuencias = data
      })
    },
    find (id) {
      return _OrdenService.find(id).then(({ data }) => {
        this.orden = data
        this.header.cliente = data.CardCode
        this.header.fechaCont = data.FechaDocumento
        this.header.bodega = data.Bodega.WhsCode
        this.udf.diagPpal = data.U_PHR_DiagnosPpal
        this.udf.cod_mpio = data.U_PHR_CodMunicipio
        bodegasStore.bodega_seleccionada = {
          ...data.Bodega,
          name: data.Bodega.WhsCode + ' - ' + data.Bodega.WhsName,
          Cuenta: null
        }
        pacientesStore.paciente_seleccionado = {
          name: data.Paciente.U_ACS_NmrIdentf + ' - ' + data.Paciente.U_ACS_NmbPct,
          Zonificacion: data.Paciente.Localizacion ? data.Paciente.Localizacion.nomDpto + ', ' + data.Paciente.Localizacion.nomMpio : '',
          ...data.Paciente
        }
        pacientesStore.tipo_id_seleccionado = data.Paciente.U_ACS_TpoIdentf
        motivosAutorizacionStore.obtenerListadoMotivosAutorizacion({ cliente: data.CardCode })
        diagnosticosStore.diagnostico_ppal = {
          ...data.DiagPpal,
          name: data.DiagPpal.CdCie + ' - ' + data.DiagPpal.DesCie
        }
        this.footer.comentarios = data.Comments
        for (const i of data.DocumentLines) {
          if (!i.ItemCode.includes('CT_')) {
            this.udf.numero_autorizacion = +i.U_PHR_NumAutoriza
            this.udf.fecha_autorizacion = i.U_PHR_FecAutoriza
            this.udf.siniestro = +i.U_PHR_Siniestro
            const unidades = [{
              value: +i.Medicamento.NumInSale,
              description: i.Medicamento.SalUnitMsr,
              code: +i.Medicamento.NumInSale === 1 ? 'tYes' : 'tNo'
            }]
            if (+i.Medicamento.NumInSale > 1 && i.Medicamento.InvntryUom) {
              unidades.push({
                value: +i.Medicamento.SalPackUn,
                description: i.Medicamento.InvntryUom,
                code: 'tYes'
              })
            }
            const lotes = []
            let sumaStock = 0
            if (i.StockMovements.length) {
              for (const j of i.StockMovements) {
                lotes.push({
                  BatchNumber: j.BatchNumber,
                  Quantity: Math.abs(j.Quantity),
                  cantstock: j.Lote.cantstock,
                  ItemCode: j.ItemCode,
                  WhsCode: j.WhsCode,
                  ObjectType: j.ObjectType
                })
                sumaStock += j.Lote.cantstock
              }
            }
            medicamentosStore.medicamentos_tabla.push({
              id_ovdt: i.Id,
              ItemCode: i.ItemCode,
              ItemName: i.Medicamento.ItemName,
              GrpCode: i.GrpCode,
              UseBaseUnits: i.UseBaseUnits,
              UnitsOfMeasurment: +i.UnitsOfMeasurment,
              WarehouseCode: i.WarehouseCode,
              UnitPrice: i.UnitPrice,
              U_PHR_SeguiEntrega: i.U_PHR_SeguiEntrega,
              U_PHR_NumEntregas: +i.U_PHR_NumEntregas,
              U_PHR_Exonerado: i.U_PHR_Exonerado,
              U_PHR_CuotaRecupe: i.U_PHR_CuotaRecupe,
              U_PHR_CtdPrescrita: +i.U_PHR_CtdPrescrita,
              U_PHR_FecPrescrip: i.U_PHR_FecPrescrip,
              U_PHR_Frecuencia: i.U_PHR_Frecuencia,
              U_PHR_DuraTratami: i.U_PHR_DuraTratami,
              U_PHR_CdHomologo: i.U_PHR_CdHomologo,
              U_PHR_NomHomologo: i.U_PHR_NomHomologo,
              U_PHR_CntHomologo: i.U_PHR_CntHomologo,
              U_PHR_PrHomologo: i.U_PHR_PrHomologo,
              U_PHR_TotalHomologo: i.U_PHR_TotalHomologo,
              U_PHR_NumAutoriza: i.U_PHR_NumAutoriza,
              U_PHR_FecAutoriza: i.U_PHR_FecAutoriza,
              U_PHR_NoAcCTC: i.U_PHR_NoAcCTC,
              U_PHR_FchAcCTC: i.U_PHR_FchAcCTC,
              U_PHR_FchSolActCTC: i.U_PHR_FchSolActCTC,
              U_PHR_CodMipres: i.U_PHR_CodMipres,
              U_PHR_NumDirec: i.U_PHR_NumDirec,
              U_PHR_FecPres: i.U_PHR_FecPres,
              U_PHR_JunMedi: i.U_PHR_JunMedi,
              U_PHR_Siniestro: i.U_PHR_Siniestro,
              CostingCode: i.CostingCode,
              U_PHR_Sta_Pen: i.U_PHR_Sta_Pen,
              FreeText: i.FreeText,
              U_PHR_IdPrincipal: i.U_PHR_IdPrincipal,
              LineNum: +i.LineNum,
              LineStatus: i.LineStatus,
              Frecuencia: i.Frecuencia ? i.Frecuencia.Name : '',
              DurTrata: i.DurTrata ? i.DurTrata.Name : '',
              SegEnt: i.SegEnt ? i.SegEnt.Name : '',
              U_PHR_RegMed: i.U_PHR_RegMed ? {
                ...i.Medico,
                name: i.Medico.RegMedico + ' - ' + i.Medico.NomMedico
              } : '',
              U_PHR_IPSPrest: i.U_PHR_IPSPrest ? i.Ips : i.U_PHR_IPSPrest,
              DelivrdQty: i.DelivrdQty,
              OpenCreQty: i.OpenCreQty,
              Quantity: i.Quantity,
              unidades_medida: unidades,
              unidadSeleccionada: unidades.find(e => e.code === i.UseBaseUnits),
              name: '(Cant: ' + sumaStock + ') - ' + i.ItemCode + ' - ' + i.Medicamento.ItemName,
              StockMovements: lotes,
              apartados: [],
              OnHandQty: sumaStock,
              SWW: i.Medicamento.SWW
            })
          }
        }
        return data
      })
    },
    getUltimaOrden () {
      _OrdenService.obtenerUltimaOrden().then(({ data }) => {
        this.ultima_orden = data.Id + 1
      })
    },
    cancelarOrden (id) {
      return _OrdenService.cancelarOrden(id).then(({ data }) => {
        return data
      })
    },
    resetData () {
      return new Promise((resolve, reject) => {
        const resetear = () => {
          this.campos_medicamentos_validacion = []
          this.creando_orden = false
          configGlobal.columnas_tabla = configGlobal._columnas_tabla_inicial
          this.header = {
            fechaCont: dayjs().format('YYYY-MM-DD'),
            cliente: '',
            bodega: ''
          }
          this.udf = {
            zonificacion: '',
            diagPpal: '',
            ex_cuota: '02',
            ex_pac: '',
            cod_mpio: 0,
            numero_autorizacion: null,
            fecha_autorizacion: '',
            siniestro: null,
            ciudad: 0,
            dispensador: 0
          }
          this.footer = {
            comentarios: ''
          }
          this.limpiar_orden = true
          this.orden = {
            Cliente: {},
            Bodega: {},
            DocumentLines: [],
            Paciente: {},
            MotAuto: {},
            DiagPpal: {
              CdCie: '',
              DesCie: ''
            }
          }
          this.pago_sincronizado_sap = false
          this.medicamentos_originales_find = []
          medicamentosStore.$patch((state) => {
            state.listado_medicamentos = []
            state.medicamentos_tabla = []
            state.info_medicamento = {}
          })
          motivosAutorizacionStore.$patch((state) => {
            state.listado_motivos_autorizacion = []
            state.motivo_autorizacion_seleccionado = 0
          })
          entregas.$patch((state) => {
            state._orden = {
              Cliente: {},
              Bodega: {},
              DocumentLines: [],
              Paciente: {},
              MotAuto: {},
              DiagPpal: {
                CdCie: '',
                DesCie: ''
              }
            }
            state._medicamentos_eliminados = []
            state._medicamentos_entregar = []
          })
          calculoCuotaStore.$patch((state) => {
            state.valor_max_cobro = 0
          })
          bodegasStore.$patch((state) => {
            state.bodega_seleccionada = ''
          })
          pacientesStore.$patch((state) => {
            state.listado_pacientes = []
            state.paciente_seleccionado = ''
            state.historia_clinica = []
          })
          diagnosticosStore.diagnostico_ppal = ''
        }
        resolve(resetear())
      })
    },
    eliminarLineaOvdt (id) {
      return _OrdenService.eliminarLineaOrden(id).then(({ data }) => {
        return data
      })
    },
    getHistorialOv (ov) {
      _OrdenService.findlogsByOv(ov).then(({ data }) => {
        const listado = []
        if (data.length) {
          for (const i of data) {
            if (listado.length && listado.some(a => a.hora === i.createdAt)) {
              listado.find(a => a.hora === i.createdAt).registros.push({
                ...i,
                afterTextJson: JSON.parse(i.afterTextJson),
                beforeTextJson: i.beforeTextJson ? JSON.parse(i.beforeTextJson) : null
              })
            } else {
              listado.push({
                hora: i.createdAt,
                registros: [
                  {
                    ...i,
                    afterTextJson: JSON.parse(i.afterTextJson),
                    beforeTextJson: i.beforeTextJson ? JSON.parse(i.beforeTextJson) : null
                  }
                ]
              })
            }
          }
        }
        this.state = listado
      })
    },
    reemplazarMedicamento (id, payload) {
      return _OrdenService.reemplazarMedicamento(id, payload).then(({ data }) => {
        return data
      })
    },
    findPagoRecibido (ov) {
      _SyncPagosService.find(ov).then(({ data }) => {
        data ? this.pago_sincronizado_sap = data.Status === 2 : this.pago_sincronizado_sap = false
      })
    },
    listarSeguimientoEntregas () {
      _SeguimientoEntregasService.listarSeguimientoEntregas().then(({ data }) => {
        this.listado_seguimientos_entrega = data
      })
    },
    listarDuracionesTratamiento () {
      _DuracionTratamientoService.listarDuracionTratamiento().then(({ data }) => {
        this.listado_duraciones_tratamiento = data
      })
    },
    validacionOvSincronizadaSap (id) {
      return _OrdenService.validarOvSync(id).then(({ data }) => {
        if (data) {
          return data
        } else {
          _SyncPagosService.find(id).then(({ data }) => {
            data ? this.pago_sincronizado_sap = data.Status === 2 : this.pago_sincronizado_sap = false
          })
        }
      })
    },
    buscarDispensador () {
      return _OrdenService.buscarDispensador().then(({ data }) => {
        this.listado_dispensadores = data
        return data
      })
    },
    buscarCiudad () {
      return _OrdenService.buscarCiudad().then(({ data }) => {
        this.listado_ciudades = data
        return data
      })
    }
  }
})
